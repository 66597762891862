import { Component } from "react";

class DudhalaWork extends Component {
    componentDidMount() {
        window.location.href =
            "https://photos.google.com/share/AF1QipMcmOIt4pHVFTPlS0YZs41LcTr5Ky4XTk0C4wAy13YpSsH0vLfRmI2TKNTpCmk4Bg?key=UktjeHBYSnlHZVZsenAwTG9qU05mdUlHTFlMd2pn";
    }
    render() {
        return true;
    }
}

export default DudhalaWork;
